
import React from 'react';
import './App.module.scss';
// import {
//   BrowserRouter,
//   Route,
//   Switch,
// } from 'react-router-dom';
// import routes from './routes';
// import TabsRoutes from './TabsRoutes';
import ProfilePicture from './components/SignUpSteps/ProfilePicture';
import { MockIphoneXContainer } from './mocks/divs';

function App() {
  return (
    <MockIphoneXContainer>
      <ProfilePicture />
    </MockIphoneXContainer>
  );
}

export default App;
