// @flow
import faker from 'faker';
import React from 'react';

export function mockDivs(count) {
  return [...Array(count)].map((item, index) => {
    return (
      <div
        key={`${item + index}`}
        style={{
          background: `${faker.internet.color()}`,
          width: '100%',
          height: '300px',
          margin: '20px 0',
        }}
        className="_t_mockdivs"
      />
    );
  });
}

export function MockIphoneXContainer({ children }: {children: React.Node}) {
  return (
    <div
      style={{
        width: '375px',
        height: '812px',
        background: '#101018',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
}
