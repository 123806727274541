import React from 'react';
import s from '../style.module.scss';
import Header from '../../../containers/Header';

type Props = {
  onFileUpload: Function,
}


function ProfilePictureUpload(props: Props) {
  const { onFileUpload } = props;
  return (
    <div className={s.formContainer}>

      <Header screenTitle="Choose or take a profile picture" />

      <div className={s.formContainer__upload}>
        <label
          htmlFor="camera"
          className={s.camera}
        >
          <input
            type="file"
            accept="camera"
            className={s.fileUpload}
            onChange={onFileUpload}
            id="camera"
          />
          #selfie
        </label>
        <label
          htmlFor="image"
          className={s.image}
        >
          <input
            type="file"
            accept="image/*"
            className={s.fileUpload}
            onChange={onFileUpload}
            id="image"
          />
          #upload
        </label>
      </div>
    </div>
  );
}

export default ProfilePictureUpload;
