import React, { useState } from 'react';
import ProfilePictureUpload from '../ProfilePictureUpload';
import ProfilePictureCheck from '../ProfilePictureCheck';
import fileToBase64 from '../../../utils/fileToBase64';

type Props = {
  onChooseImageClick: Function,
}

function ProfilePicture(props: Props) {
  const {
    onChooseImageClick,
  } = props;

  const [profilePicture, setProfilePicture] = useState(null);


  function onFileUpload(e) {
    fileToBase64(e.target.files[0]).then(res => setProfilePicture(res));
  }

  return (
    <>
      {
        profilePicture === null
          ? (
            <ProfilePictureUpload
              onFileUpload={(e) => { onFileUpload(e); }}
            />
          )
          : (
            <ProfilePictureCheck
              imageSource={profilePicture}
              onChooseImageClick={onChooseImageClick}
              onBackClick={() => { setProfilePicture(null); }}
            />
          )
      }
    </>
  );
}

export default ProfilePicture;
