// @flow
import React from 'react';
import s from './style.module.scss';

type ButtonProps = {
  className?: string,
  onClick?: Function,
  content: string,
  leftIcon?: Node,
  rightIcon?: Node,
  primary?: boolean,
  secondary?: boolean,
  transparent?: boolean,
}

function Button(props: ButtonProps) {
  const {
    onClick,
    content,
    className,
    leftIcon,
    rightIcon,
    primary,
    secondary,
    transparent,
  } = props;
  return (
    <button
      className={`
        ${s.button} 
        ${primary && `${s.button__primary} _t_main_button`} 
        ${secondary && `${s.button__secondary} _t_secondary_button`} 
        ${transparent && `${s.button__transparent} _t_transparent_button`} 
        ${className}`}
      type="button"
      onClick={onClick}
    >
      {leftIcon !== null && (
        <div className={`${s.icon} ${s.icon__left}`}>
          {leftIcon}
        </div>
      )}
      {content}
      {rightIcon !== null && (
        <div className={`${s.icon} ${s.icon__right}`}>
          {rightIcon}
        </div>
      )}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  className: '',
  leftIcon: null,
  rightIcon: null,
  primary: false,
  secondary: false,
  transparent: false,
};

export default Button;
